
/**
 * ScrollMagic
 * Library Documentation: https://scrollmagic.io/docs/
 * Examples: https://scrollmagic.io/examples/
 * 
 */

( () => {

    let controller,
    homeController;

    // Force Resize on page load to set correct height for animations
    window.addEventListener("load", (e) => {
        window.dispatchEvent(new Event('resize'));
    });

    // Reset animations on Resize
	window.addEventListener("resize", () => {
        if(controller) {
            destroyController();
        }
	    activateScrollAnimations();
	});

	const destroyController = () => controller.destroy(true);

    const activateScrollAnimations = () => {
       

        const windowHeight = document.documentElement.clientHeight;

        controller = new ScrollMagic.Controller();    

    // ---------- PARALLAX ------------ //
        const sectionParallax = document.querySelector(".b-heading-parallax");
        if (sectionParallax) {
            if (document.documentElement.clientWidth >= 992) {
                // Tweens
                const image1 = new TweenMax.to(".b-heading-parallax .b-heading-parallax__image--1", 1, {top: "-15%", ease: Linear.easeNone});
                const image2 = new	TweenMax.to(".b-heading-parallax .b-heading-parallax__image--2", 1.5, {top: "0%", ease: Linear.easeNone});
                const image3 = new	TweenMax.to(".b-heading-parallax .b-heading-parallax__image--3", 1, {top: "-40%", ease: Linear.easeNone});
                const image4 = new	TweenMax.to(".b-heading-parallax .b-heading-parallax__image--4", 1.7, {bottom: "50%", ease: Linear.easeNone});
                const image5 = new	TweenMax.to(".b-heading-parallax .b-heading-parallax__image--5", 1.2, {bottom: "120%", ease: Linear.easeNone});
                const image6 = new	TweenMax.to(".b-heading-parallax .b-heading-parallax__image--6", 1.4, {bottom: "70%", ease: Linear.easeNone});
                const parallaxTween = new TimelineMax().add([image1, image2 ,image3, image4, image5, image6 ]);
                 // Scene
                const playParallax = new ScrollMagic.Scene({
                    triggerElement: '.b-heading-parallax',
                    duration: windowHeight * 2,
                })
                .triggerHook(0.8)
                .setTween(parallaxTween)
                // .addIndicators()
                .addTo(controller);
            } else {
                // Tweens
                const image1 = new TweenMax.to(".b-heading-parallax .b-heading-parallax__image--1", 1.8, {top: "-90%", ease: Linear.easeNone});
                const image2 = new	TweenMax.to(".b-heading-parallax .b-heading-parallax__image--2", 1, {top: "-60%", ease: Linear.easeNone});
                const image4 = new	TweenMax.to(".b-heading-parallax .b-heading-parallax__image--4", 1.2, {bottom: "70%", ease: Linear.easeNone});
                const image5 = new	TweenMax.to(".b-heading-parallax .b-heading-parallax__image--5", 2, {bottom: "40%", ease: Linear.easeNone});
                const parallaxTween = new TimelineMax().add([image1, image2, image4, image5 ]);
                 // Scene
                const playParallax = new ScrollMagic.Scene({
                    triggerElement: '.b-heading-parallax',
                    duration: windowHeight * 2,
                })
                .triggerHook(0.8)
                .setTween(parallaxTween)
                // .addIndicators()
                .addTo(controller);
            }
        }
        

    // ---------- PIN SECTION IMAGE ------------ //

        const sectionImage = document.querySelectorAll(".scroll-pin-section  .scroll-pin-image");
        if (document.documentElement.clientWidth >= 992) {
            
            sectionImage.forEach((image) => {
                const section = image.closest(".scroll-pin-section");
                const sectionHeight = section.offsetHeight;
                const imageHeight = image.offsetHeight;
                const duration = sectionHeight - imageHeight > 0 ? sectionHeight - imageHeight : 1;
                section.style.maxHeight = `${sectionHeight}px`;
                const pinImage = new ScrollMagic.Scene({
                    triggerElement: image,
                    offset: 0,
                    duration: `${duration}px`,
                    reverse:true
                }).triggerHook(0)
                .setPin(image)
                .addTo(controller);
            });
        }


    // ---------- PIN BLOCK SECTION IMAGE ------------ //

        const blogCatSection = document.querySelectorAll(".scroll-pin-image-under");
        if (document.documentElement.clientWidth >= 992) {
            
            blogCatSection.forEach((section) => {
                const image = section.querySelector(".scroll-pin-image");
                const wrapper = section.parentElement;
                const sectionHeight = section.offsetHeight;
                const unpinImage = new ScrollMagic.Scene({
                    triggerElement: section,
                    offset: sectionHeight,
                    // duration: `${sectionHeight}px`,
                    reverse:true
                }).triggerHook(1)
                .setClassToggle(image, "unpin")
                .addTo(controller);
            });
        }


    // ---------- PIN SECTION HEADER ------------ //

        const sectionHeader = document.querySelectorAll(".scroll-pin-section  .scroll-pin-header");
        if (document.documentElement.clientWidth >= 992) {
            
            sectionHeader.forEach((header) => {
                const section = header.closest(".scroll-pin-section");
                const sectionHeight = section.offsetHeight;
                const headerHeight = header.offsetHeight;
                const duration = sectionHeight - headerHeight > 0 ? sectionHeight - headerHeight : 1;
                section.style.maxHeight = `${sectionHeight}px`;
                const pinHeader = new ScrollMagic.Scene({
                    triggerElement: header,
                    offset: 0,
                    duration: `${duration}px`,
                    reverse:true
                }).triggerHook(0)
                .setPin(header)
                .addTo(controller);
            });
        }



    // ---------- PIN IMAGE GALLERY ------------ //

        const sectionImageGallery = document.querySelectorAll(".b-image-gallery");
        if (document.documentElement.clientWidth >= 992) {
            
            sectionImageGallery.forEach((gallery) => {
                const scrollContainer = gallery.querySelector(".b-image-gallery__wrapper");

                //  Tween
                const galleryWidth = scrollContainer.scrollWidth - scrollContainer.offsetWidth;
                const galleryPossition = new TweenMax.to(scrollContainer, 2, {
                    x: -galleryWidth, ease: Linear.easeNone
                });

                // Scene
                const pinGallery = new ScrollMagic.Scene({
                    triggerElement: gallery,
                    offset: 0,
                    duration: '100%',
                    reverse:true
                }).triggerHook(0)
                .setPin(gallery)
                .setTween(galleryPossition)
                .addTo(controller);

                // Fix scroll-set-bg
                gallery.parentElement.classList.add('scroll-set-bg');
                gallery.parentElement.setAttribute('data-body-bg', 'white');
                gallery.classList.remove('scroll-set-bg');
            });
            
        }


    // ---------- PIN POSTS AREA ------------ //

        const sectionPosts = document.querySelectorAll(".posts-area");
        if (document.documentElement.clientWidth >= 992) {
            
            sectionPosts.forEach((section) => {
                const scrollContainer = section.querySelector(".posts-area__list-wrapper");

                //  Tween
                const sectionyWidth = scrollContainer.scrollWidth - scrollContainer.offsetWidth;
                const sectionPossition = new TweenMax.to(scrollContainer, 2, {
                    x: -sectionyWidth, ease: Linear.easeNone
                });

                // Scene
                const pinSection = new ScrollMagic.Scene({
                    triggerElement: section,
                    offset: "40px",
                    duration: '100%',
                    reverse:true
                }).triggerHook(0)
                .setPin(section)
                .setTween(sectionPossition)
                .addTo(controller);

                // Fix scroll-set-bg
                section.parentElement.classList.add('scroll-set-bg');
                section.parentElement.setAttribute('data-body-bg', 'white');
                section.classList.remove('scroll-set-bg');
            });
            
        }




    // ------ INDICATE CURRENT SECTION IN MENU (Rooms & Intallations) ------- //
        const installations = document.querySelectorAll(".b-section-intro");
        const rooms = document.querySelectorAll(".b-room");
        const menu = document.querySelector(".b-items-menu");
        if ((installations.length || rooms.length ) && menu) {
            menu.style.opacity = 1;
            markCurrentSection(installations.length ? installations : rooms);
        }

        function markCurrentSection(sections) {
            const menuLinks = document.querySelectorAll(".b-items-menu__list__item > a");
            const menuToggleBtn = document.querySelector(".b-items-menu__toggle-btn");
            menuLinks[0].classList.add('current-item');
            menuToggleBtn.append(menuLinks[0].textContent)
            sections.forEach((section) => {
          
                const sectionHeight = section.offsetHeight;
                // Scene
                const getCurrentSection = new ScrollMagic.Scene({
                    triggerElement: section,
                    offset: 0,
                    duration: `${sectionHeight}px`,
                    reverse: true
                }).triggerHook(0.5)
                .on('start', (event) => {
                    if ((event.scrollDirection == 'FORWARD')) {
                        const prevSection = section.previousElementSibling.querySelector('.b-room.wipe-section');
                        if (!prevSection || prevSection.getBoundingClientRect().top !== 0 && section.getBoundingClientRect().top < section.getBoundingClientRect().height / 2) {                            
                            const currentSectionId = section.id;
                            const currentMenuItem = menu.querySelector(`[data-target="${currentSectionId}"]`);
                            for ( const link of menuLinks ) { link.classList.remove('current-item'); }
                            currentMenuItem.classList.add('current-item');
                            if (document.documentElement.clientWidth < 992) {
                                menuToggleBtn.textContent = "";
                                menuToggleBtn.append(currentMenuItem.textContent)
                            }
                        }
                    } else if (event.scrollDirection == 'REVERSE') {
                        if (section.previousElementSibling && (section.previousElementSibling.classList.contains('b-section-intro') || section.previousElementSibling.classList.contains('b-room')) ) {
                            const currentSectionId = section.previousElementSibling.id;
                            const currentMenuItem = menu.querySelector(`[data-target="${currentSectionId}"]`);
                            for ( const link of menuLinks ) { link.classList.remove('current-item'); }
                            currentMenuItem.classList.add('current-item');
                            if (document.documentElement.clientWidth < 992) {
                                menuToggleBtn.textContent = "";
                                menuToggleBtn.append(currentMenuItem.textContent)
                            }
                        } else {
                            for ( const link of menuLinks ) { link.classList.remove('current-item'); }
                            menuLinks[0].classList.add('current-item');
                            if (document.documentElement.clientWidth < 992) {
                                menuToggleBtn.textContent = "";
                                menuToggleBtn.append(menuLinks[0].textContent)
                            }
                        }
                    }
                })
                .addTo(controller);
                
            });
        }
   
    // ---------- NAV MENU COLOR ------------ //
        const triggerSections = document.querySelectorAll(".nav-white");
        const siteHeader = document.querySelector(".site-header");
        
        if (triggerSections.length > 0) {
            triggerSections.forEach((section) => {
                const duration = section.offsetHeight;
                // Scene
                const changeColor = new ScrollMagic.Scene({
                    triggerElement: section,
                    // offset: '-50px',
                    duration: `${duration}px`,
                    reverse:true
                }).triggerHook(0.5)
                .setClassToggle(siteHeader, "site-header--white")
                .addTo(controller);

                if (section.classList.contains('nav-blue-mobile')) {
                    // Scene
                    const changeColor = new ScrollMagic.Scene({
                        triggerElement: section,
                        duration: `${duration}px`,
                        reverse:true
                    }).triggerHook(0.5)
                    .setClassToggle(siteHeader, "nav-blue-mobile")
                    .addTo(controller);
                }
            });
        }


    // ---------- SET BG COLOR ------------ //
        const addToAnyContainer = document.querySelector(".addtoany_content");
        if (addToAnyContainer) {
            addToAnyContainer.classList.add("scroll-set-bg");
            addToAnyContainer.setAttribute("data-body-bg", "white");
        }
        const bgTriggerSections = document.querySelectorAll(".scroll-set-bg");
        if (bgTriggerSections.length > 0) {
            bgTriggerSections.forEach((section) => {
                const bgColor = section.getAttribute("data-body-bg");
                const duration = section.offsetHeight - 1;
                // Scene
                const changeColor = new ScrollMagic.Scene({
                    triggerElement: section,
                    duration: `${duration}px`,
                    reverse:true
                }).triggerHook(0.5)
                .setClassToggle("body", `bg_${bgColor}`)
                .addTo(controller);
            });
        }

    // ---------- SET HEADER BG COLOR TO WHITE ------------ //
        const bgHeaderTriggerSections = document.querySelectorAll(".scroll-bg-header, .mibe-engine-page-container");
        if (bgHeaderTriggerSections.length > 0) {
            bgHeaderTriggerSections.forEach((section) => {
                const isBookingEngine = section.classList.contains('mibe-engine-page-container');
                const homeTitleWidth = new TweenMax.to(".site-header", {
                    background: "rgba(255, 0, 0, 0.2)",
                });
                // Scene
                const changeColor = new ScrollMagic.Scene({
                    triggerElement: section,
                    offset: isBookingEngine ? '-200px' : "300px",
                    reverse: true
                }).triggerHook(0.4)
                .setTween(homeTitleWidth)
                .on('start', () => {
                    document.querySelector(".site-header").style.background = "#ffffff";
                    const menuItems = document.querySelector(".b-items-menu");
                    if (menuItems) {
                        menuItems.style.background = "#ffffff";
                    }
                })
                .addTo(controller);
            });
        }


    // ---------- Home Title Animation ------------ //

        const homeHero = document.querySelectorAll(".site-home .b-hero-image");
        
        if(homeHero.length && document.documentElement.clientWidth >= 992) {
            homeController = new ScrollMagic.Controller();  
            const homeTitle = homeHero[0].querySelector(".site-home .b-hero-image .b-hero-image__title");
            //  Tween
        
            const homeTitleWidth= new TweenMax.to(homeTitle, 1, {
                width: 345,
            });
            const homeTitleOpacity = new TweenMax.to(homeTitle, 0.5, {
                opacity: 0,
            });

            // Scene
            const animateTitle = new ScrollMagic.Scene({
                triggerElement: homeTitle / 2,
                offset: "32px",
                reverse: true,
                duration: "40%"
            }).triggerHook(0.5)
            .setTween(homeTitleWidth)
            .addTo(homeController);

            const pinTitle = new ScrollMagic.Scene({
                triggerElement: homeTitle,
                offset: "32px",
                reverse: true,
            }).triggerHook(0)
            .setTween(homeTitleOpacity)
            .setPin(homeTitle)
            .setClassToggle("h1.site-title", 'show')
            .addTo(homeController);

        } else if (homeHero.length && document.documentElement.clientWidth < 992) {
            homeController = new ScrollMagic.Controller();  
            const homeTitle = homeHero[0].querySelector(".site-home .b-hero-image .b-hero-image__title");
            // Scene
            const pinTitle = new ScrollMagic.Scene({
                triggerElement: homeTitle,
                offset: "22px",
                reverse: true,
            }).triggerHook(0)
            .setClassToggle("h1.site-title", 'show')
            .addTo(homeController);
        }

          // ROOMS wipe-section
        const wipeSection = document.querySelector(".wipe-section");
        if (wipeSection) {
            const roomsSection = wipeSection.nextElementSibling;
            const setPossitionClass = new ScrollMagic.Scene({
                triggerElement: wipeSection,
                offset: 0,
                duration: `200%`,
                reverse:true
            }).triggerHook(0)
            .on("end", (e) => {
                if (e.scrollDirection == 'FORWARD') {
                    roomsSection.classList.add('relative');
                } else { 
                    roomsSection.classList.remove('relative');
                }
            })
            .addTo(controller);
        }
    }

} ) ();