import getCurrentLang from './get-current-lang';

export default ( window.addEventListener('load', () => {
    const pressLoginForm = document.getElementById('loginform');
    pressLoginForm ? pressLoginForm.addEventListener('submit', function(event) {
        event.preventDefault();

        const formData = new FormData(this);
        const currentLang = getCurrentLang();

        // Perform AJAX request
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${currentLang && currentLang !== 'es' ? `/${currentLang}`: ''}/wp-login.php`, true);
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    // Extract the login error message from the response
                    const responseHTML = document.createElement('div');
                    responseHTML.innerHTML = xhr.responseText;
                    const loginError = responseHTML.querySelector('#login_error');
                    // Update the response message on the page
                    const responseMessage = document.getElementById('response-message');
                    responseMessage.innerHTML = '';
                    responseMessage.style.border = 'none';
                    if (responseMessage, loginError) {
                        responseMessage.style.border = '2px solid #ffb900';
                        showErrorMessage(responseMessage, loginError, currentLang);
                    } else {
                        const galleryPageSlug = getGallerySlug(currentLang); 
                        window.location.assign(window.location.href + galleryPageSlug);
                    }
                } else {
                    console.error('Error occurred: ' + xhr.status);
                }
            }
        };
        xhr.send(formData);
    }) : '';

    const showErrorMessage = (responseMessage, loginError, currentLang) => {
        const resetLink = loginError.querySelector('a');
        // responseMessage.innerHTML = loginError.textContent;
        if (resetLink) {
            resetLink.remove();
            const contactLink = document.createElement('a');
            contactLink.href = 'mailto:info@hotelbendinat.es';
            contactLink.textContent = 'info@hotelbendinat.es';
            const forgotPwdText = currentLang == 'es' ? 
            `¿Olvidaste tu contraseña? Por favor contacta a `:
            currentLang == 'de' ?
            'Passwort vergessen? Bitte kontaktieren Sie ' :
            'Forgot your password? Please contact ';
            loginError.append(forgotPwdText);
            loginError.append(contactLink);
        }
        responseMessage.append(loginError);
    }

    const logoutUser = () => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', '/wp-admin/admin-ajax.php?action=custom_logout', true);
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    //Redirect to press page
                    window.location.assign(window.location.href.replace(/\/[^/]*\/$/, '/'));
                } else {
                    console.error('Error occurred: ' + xhr.status);
                }
            }
        };
        xhr.send();
    }

    // Handle logout link click event
    const logOutBtn = document.getElementById('logout-link');
    logOutBtn ? 
    logOutBtn.addEventListener('click', (event) => {
        event.preventDefault();
        logoutUser();
    }): '';
}));

const getGallerySlug = (current_lang) => {
    if (current_lang == "es") { 
        return "/galeria/";
    } else if (current_lang == "de") {
        return "/galerie/";
    } else {
        return "/gallery/";
    }
}