export default ( window.addEventListener('load', () => {


    const wipeSections = document.querySelectorAll(".wipe-section");

    if (wipeSections.length) {

        // Add wipes wrapper
        const wipesWrapper = document.createElement('div');
        wipesWrapper.classList.add('wipes-wrapper');

        wipeSections[0].parentNode.insertBefore(wipesWrapper, wipeSections[0].nextSibling);
        wipesWrapper.append(...wipeSections);

        // Set the position for each section
        wipeSections.forEach((section, i) => {
            if (i < wipeSections.length - 1) {
                const position = section.querySelector(".wipe-section__row").offsetHeight;
                section.style.bottom = `-${position}px`
            } else {
                const position = section.querySelector(".wipe-section__row").offsetHeight - document.documentElement.clientHeight;
                section.style.bottom = `-${position}px`
            }

        });

        const itemsMenu = document.querySelector('.b-items-menu')
        const itemsMenuToggleBtn = document.querySelector('.b-items-menu__toggle-btn');
        const itemsMenuLinks = document.querySelectorAll('.b-items-menu > a');

        //  Toggle section Items menu (mobile)
        if(itemsMenuToggleBtn) {
            itemsMenuToggleBtn.addEventListener('click', (event) => {
                itemsMenu.classList.toggle( 'open' );
            })
        }
        // Scroll to section from Archor Link
        const linksToSection = document.querySelectorAll(".b-items-menu__list__item > a");

        for ( const link of linksToSection ) {
            // link.addEventListener( 'click', scrollToSection );
            link.addEventListener( 'click', closeMenu );
        }

        function scrollToSection(event) {
            event.preventDefault();
            itemsMenu.classList.toggle( 'open' );
            const targetId = event.target.href.split('#')[1];
            window.location.hash = targetId;
            const sectionScrollPosition = getSectionScrollPosition(document.querySelector(`#${targetId}`), '.wipe-section');
            window.scrollTo(0, sectionScrollPosition);
        }

        const getSectionScrollPosition = function (elem, selector) {
            // Get the prev sibling element
            const prevElemHeight = elem.parentNode.previousElementSibling.offsetHeight;
            let sibling = elem.previousElementSibling;
            let scrollPosition = prevElemHeight;
            // If there's no selector, return the first sibling
            if (!selector) {
                return sibling
            } else {
                // If the sibling matches our selector, use it
                // If not, jump to the next sibling and continue the loop
                while (sibling) {
                    // if (sibling.matches(selector)) return position;
                    if (sibling.matches(selector)) {
                        scrollPosition = scrollPosition + sibling.offsetHeight;
                        
                    }
                    sibling = sibling.previousElementSibling;
                    
                }
                return scrollPosition;
            }

        };

        function closeMenu(event) {
            if (document.documentElement.clientWidth < 992) {
                itemsMenu.classList.toggle( 'open' );
            }
        }


        // If linked to first section elem, wipe up the lines (rooms & installations)
        const fistSectionId = wipeSections[1].id;
        if (window.location.hash === `#${fistSectionId}`) {
           window.scrollTo(0, wipeSections[0].offsetHeight);
        }

    }

}));