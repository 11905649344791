/**
 * File navigation.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */


export default ( window.addEventListener('load', () => {

	const siteNavigation = document.getElementById( 'site-navigation' );
	// Return early if the navigation doesn't exist.
	if ( ! siteNavigation ) {
		return;
	}

	const btnMenuIcon = document.querySelector( '.header__mobile__btn__icon' );

	const button = document.querySelector( '.js-site-header__nav__toggle' );
	const closeButton = document.querySelector( '.main-navigation__toggle-btn' );
	const closeButtonDesktop = document.querySelector( '.main-navigation__close-btn' );
	// Return early if the button doesn't exist.
	if ( 'undefined' === typeof button ) {
		return;
	}

	const menu = siteNavigation.getElementsByTagName( 'ul' )[ 0 ];
	// Hide menu toggle button if menu is empty and return early.
	if ( 'undefined' === typeof menu ) {
		button.style.display = 'none';
		return;
	}

	if ( ! menu.classList.contains( 'nav-menu' ) ) {
		menu.classList.add( 'nav-menu' );
	}

	// Toggle the .toggled class and the aria-expanded value each time the button is clicked.
	button.addEventListener( 'click', () => {
        siteNavigation.classList.toggle( 'open' );
        btnMenuIcon.classList.toggle( 'open' );

        button.toggleAttribute('aria-expanded');

		if ( button.getAttribute( 'aria-expanded' ) === 'true' ) {
			button.setAttribute( 'aria-expanded', 'false' );
		} else {
			button.setAttribute( 'aria-expanded', 'true' );
		}
	} );
	closeButton.addEventListener( 'click', () => {
        siteNavigation.classList.toggle( 'open' );
		btnMenuIcon.classList.toggle( 'open' );

        button.toggleAttribute('aria-expanded');

		if ( button.getAttribute( 'aria-expanded' ) === 'true' ) {
			button.setAttribute( 'aria-expanded', 'false' );
		} else {
			button.setAttribute( 'aria-expanded', 'true' );
		}
	} );
	closeButtonDesktop.addEventListener( 'click', closeMenu );

	function closeMenu() {
		siteNavigation.classList.toggle( 'open' );
		btnMenuIcon.classList.toggle( 'open' );

        button.toggleAttribute('aria-expanded');

		if ( button.getAttribute( 'aria-expanded' ) === 'true' ) {
			button.setAttribute( 'aria-expanded', 'false' );
		} else {
			button.setAttribute( 'aria-expanded', 'true' );
		}
	}


	// Get all the link elements within the menu that do not have children.
	const links = menu.getElementsByTagName( 'a:not([menu-item-has-children])' );

	// Get all the link elements with children within the menu.
	const linksWithChildren = menu.querySelectorAll( '.menu-item-has-children > a, .page_item_has_children > a' );

	// Get all sub-menu links within the menu.
	const submenuLinks = menu.querySelectorAll( '.sub-menu-link' );

	// Toggle focus each time a menu link is focused or blurred.
	for ( const link of links ) {
		link.addEventListener( 'focus', toggleFocus, true );
		link.addEventListener( 'blur', toggleFocus, true );
	}

	// Toggle focus each time a menu link with children receive a touch event.
	for ( const link of linksWithChildren ) {
		link.addEventListener( 'click', toggleFocus, false );
		link.addEventListener( 'mouseenter', showSubmenu, false );
	}

	for ( const link of submenuLinks ) {
		link.addEventListener( 'click', checkIfCurrentPage, false );
	}



	/**
	 * Sets or removes .focus class on an element.
	 */
	function toggleFocus(event) {
		if ( (event.type === 'focus' || event.type === 'blur' || event.type === 'mouseenter' || event.type === 'mouseleave') && !menu.classList.contains('sub-menu-open')) {
			let self = this;
			// Move up through the ancestors of the current link until we hit .nav-menu.
			while ( ! self.classList.contains( 'nav-menu' ) ) {
				// On li elements toggle the class .focus.
				if ( 'li' === self.tagName.toLowerCase() ) {
					self.classList.toggle( 'focus' );
					siteNavigation.classList.toggle('menu-focus');

				}
				self = self.parentNode;
			}
		} else if ( (event.type === 'focus' || event.type === 'blur' || event.type === 'mouseenter' || event.type === 'mouseleave') && menu.classList.contains('sub-menu-open')) { 
			const currentOpenSubmenu = menu.querySelector('.current-sub-menu-open');
			if ( !event.target.classList.contains('current-sub-menu-open') && currentOpenSubmenu) {
				menu.classList.remove('sub-menu-open');
				currentOpenSubmenu.classList.remove('current-sub-menu-open');
				currentOpenSubmenu.classList.remove('focus');
				this.classList.toggle( 'focus' );
			}
		}


		if ( event.type === 'click' && document.documentElement.clientWidth < 992) {
			const menuItem = this.parentNode;
			event.preventDefault();
			for ( const link of menuItem.parentNode.children ) {
				if ( menuItem !== link ) {
					link.classList.remove( 'focus' );
				}
			}
			menuItem.classList.add( 'focus' );
			menuItem.classList.add( 'current-sub-menu-open' );
			siteNavigation.classList.add( 'menu-focus' );
			menuItem.parentElement.classList.add('sub-menu-open');
		}
	}

	function showSubmenu(event) {
		if (document.documentElement.clientWidth > 992) {
			const menuItem = this.parentNode;
			event.preventDefault();
			for ( const link of menuItem.parentNode.children ) {
				if ( menuItem !== link ) {
					link.classList.remove( 'focus' );
				}
			}
			menuItem.classList.add( 'focus' );
			menuItem.classList.add( 'current-sub-menu-open' );
			siteNavigation.classList.add( 'menu-focus' );
			menuItem.parentElement.classList.add('sub-menu-open');
		}
	}


	const linksBackToMenu = menu.querySelectorAll('.btn-menu-back');

	for ( const link of linksBackToMenu ) {
		link.addEventListener( 'click', backToMenu, false );
	}
	function backToMenu() {
		const subMenuParent = findAncestor(this, '.menu-item-has-children')
		subMenuParent.classList.toggle( 'focus' );
		subMenuParent.classList.toggle( 'current-sub-menu-open' );
		subMenuParent.parentElement.classList.remove('sub-menu-open');
	}

	function findAncestor (el, sel) {
		while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el,sel)));
		return el;
	}


	const mainMenuLinks = siteNavigation.querySelectorAll('.main-navigation__menu > .menu-item')

	for ( const link of mainMenuLinks ) {
		link.addEventListener( 'mouseenter', toggleFocus, false );
		link.addEventListener( 'mouseleave', toggleFocus, false );

	}



	function checkIfCurrentPage(event) {
		const currentPage = event.target.baseURI.split('#')[0];
		const targetPage = event.target.href.split('#')[0];
		if (currentPage === targetPage) {
			closeMenu();
		}
	}



	// Toggle La Buena Vida cat. menu (mobile)
	const catMenu = document.querySelector('.cat-menu')
	const catMenuToggleBtn = document.querySelector('.cat-menu__toggle-btn');
	if(catMenuToggleBtn) {
		catMenuToggleBtn.addEventListener('click', (event) => {
			catMenu.classList.toggle( 'open' );
		})
	}

}));
