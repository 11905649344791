export default ( window.addEventListener('load', () => {

    const toggleBtns = document.querySelectorAll(".js-toggle-features");

    if(toggleBtns.length) {
        toggleBtns.forEach((btn) => {
            btn.addEventListener('click', () => {
                btn.classList.toggle('active');
                btn.previousElementSibling.classList.toggle('active');
                btn.classList.contains('active') ? btn.setAttribute('aria-expanded', 'true') : btn.setAttribute('aria-expanded', 'false');
            })
        })
    }

    

    const setRoomSecionPadding = () => {
        const headerMenuHeight = document.querySelector('.site-header').offsetHeight;
        const roomsMenuHeight = document.querySelector('.b-items-menu').offsetHeight;
        const headerHeight = headerMenuHeight + roomsMenuHeight;
        roomSection.forEach((section) => section.style.paddingTop = headerHeight + 'px');
    }
    const roomSection = document.querySelectorAll('.b-room');
    
    if(roomSection.length) {
        setRoomSecionPadding();
        window.addEventListener("resize", () => {
            setRoomSecionPadding();
        });
    }
    
   

}));