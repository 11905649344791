
export default ( window.addEventListener('load', () => {

	// IMAGE GALLERY

	const galleries = document.querySelectorAll('.b-image-gallery');
	galleries.forEach((gallery) => {

		// Gallery Drag - scroll (Mobile)
		let isDown = false;
		let startX;
		let scrollLeft;
		const galleryElements = gallery.getElementsByClassName('b-image-gallery__list')[0];

		galleryElements.addEventListener('mousedown', (e) => {

			isDown = true;
			gallery.classList.add('active');
			startX = e.pageX - gallery.offsetLeft;
			scrollLeft = gallery.scrollLeft;
		});
		galleryElements.addEventListener('mouseleave', () => {
			isDown = false;
			gallery.classList.remove('active');
			});
			galleryElements.addEventListener('mouseup', () => {
			isDown = false;
			gallery.classList.remove('active');
		});
		galleryElements.addEventListener('mousemove', (e) => {
			e.preventDefault();
			if(isDown) {
				const x = e.pageX - gallery.offsetLeft;
				const walk = x - startX;
				gallery.scrollLeft = scrollLeft - walk;
			}	
		});
		
	});

}));