// ---- PRESS - GALLERY -----
//Add image download option

import getCurrentLang from './get-current-lang';

export default ( window.addEventListener('load', () => {
    const galleryItems = document.querySelectorAll('.page-template-page-press-area .vp-portfolio__item');
    const currentLang = getCurrentLang();

    galleryItems.forEach((item) => {
        const image = item.getElementsByTagName('img')[0];
        const imageData = getImageData(image);
        const itemName = item.getElementsByTagName('h2')[0].innerText;
        const format = document.createElement("span");
        const formatText = document.createTextNode(currentLang == 'es'? `Formato: ${imageData.format.toUpperCase()}` : `Format: ${imageData.format.toUpperCase()}`);
        format.appendChild(formatText);
        const downloadLink = document.createElement('a');
        const linkText = document.createTextNode(currentLang == 'pt'? 'Baixar' : currentLang == 'de' ? 'Download' : 'Download');
        downloadLink.appendChild(linkText);
        downloadLink.setAttribute('download', itemName.trim());
        downloadLink.classList.add("gallery-download-btn");

        // Make an AJAX request to get the image URL from the WordPress REST API
        fetch(`/wp-json/wp/v2/media/${imageData.id}`)
        .then((response) => response.json())
        .then((data) => {
            const imageUrl = data.source_url;
            downloadLink.href = imageUrl;
        })
        .catch((error) => {
            console.error('Error fetching image data:', error);
        });
    
        item.getElementsByTagName('figcaption')[0].appendChild(format);
        item.getElementsByTagName('figcaption')[0].appendChild(downloadLink);
    });
}));

function getImageData(element) {
    const imageData = {};
    const classList = element.classList;
    const prefix = 'wp-image-';
    for (const className of classList) {
        if (className.startsWith(prefix)) {
            imageData.id = className.replace(prefix, '');
        }
    }
    const regex = /\/([^\/?#]+)\.([^\/?#.]+)$/;
    const imageUrl = element.getAttribute('data-src');
    const matches = imageUrl.match(regex);
    if (matches) {
        imageData.name = matches[1];
        imageData.format = matches[2];
        return imageData;
    } else {
        console.error('Not possible to get image url');
        return null;
    }
}