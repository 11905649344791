import $ from 'jquery';
import 'slick-carousel';

export default $(() => {

    const sliderSingleItem = $( '.slick-slider--single-item' );
    const sliderWide = $( '.slick-slider--wide' );

    // SLIDER SINGLE ITEM
    if ( sliderSingleItem.length ) {

        setTimeout(() => {
            sliderSingleItem.slick( {
                dots: true,
                customPaging : (slider, i) => {
                    return '<a class="dot">'+ (('0' + (i + 1)).slice(-2)) +'</a>';
                },
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                speed: 200,
                cssEase: 'linear',
            } );
        }, 100 ) 
        setTimeout(() => {
            sliderSingleItem.slick("refresh");
            sliderSingleItem.slick('setPosition');
        }, 1000 ) 
    } 

    

    // SLIDER WIDE
    if ( sliderWide.length ) {
        setTimeout(() => {
            sliderWide.slick( {
                // dots: true,
                // customPaging : (slider, i) => {
                //     return '<a class="dot">'+ (('0' + (i + 1)).slice(-2)) +'</a>';
                // },
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                speed: 200,
                cssEase: 'linear',
                nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"></button>',
                prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"></button>',
            } );
        }, 100 ) 
        setTimeout(() => {
            sliderWide.slick("refresh");
            sliderWide.slick('setPosition');
        }, 1000 ) 
    } 

});