
window.addEventListener('load', () => {
    document.documentElement.clientWidth >= 992 ? '' : setNav();
});

window.addEventListener("resize", () => {
    document.documentElement.clientWidth >= 992 ? setNav() :'';
});

const setNav = () => {
    const restautantmenuItems = document.querySelectorAll('.b-restaurant-menu');
    const isDesktop = document.documentElement.clientWidth >= 992;
    if(document.querySelectorAll('.b-restaurant-menu').length) {

        const linksToSection = document.querySelectorAll(".b-restaurant-menu-nav__list__item > a");
        const itemsMenuToggleBtn = document.querySelector('.b-restaurant-menu-nav__toggle-btn');
        const itemsMenu = document.querySelector('.b-restaurant-menu-nav');
        
        // Hide items
        for ( const item of restautantmenuItems ) {
            item.style.display = "none";
        }
    
        // Show first item
        restautantmenuItems[0].style.display = "block";
        linksToSection[0].className += " current-item";
        itemsMenuToggleBtn.textContent = "";
        itemsMenuToggleBtn.append(linksToSection[0].textContent);
    
        // Add Pin to fist item
        isDesktop ? setPinToMenu(restautantmenuItems[0]) : '';
    
    
        // On click Show selected item
        for ( const link of linksToSection ) {
            link.addEventListener( 'click', showItem );
        }

        //  Toggle section Items menu (mobile)
        if(itemsMenuToggleBtn) {
            itemsMenuToggleBtn.addEventListener('touchstart', (event) => {
                itemsMenu.classList.toggle( 'open' );
                event.stopImmediatePropagation();
            })
        } 

        function showItem(event) {
            event.preventDefault();
            const targetId = event.target.href.split('#')[1];
            const targetElem = document.getElementById(targetId);
            for (let i = 0; i < restautantmenuItems.length; i++) {
                restautantmenuItems[i].style.display = "none";
            }
        
            for (let i = 0; i < linksToSection.length; i++) {
                linksToSection[i].className = linksToSection[i].className.replace(" current-item", "");
            }
            targetElem.style.display = "block";
            event.currentTarget.className += " current-item";
            window.scrollTo(0, 0);
        
            itemsMenuToggleBtn.textContent = "";
            itemsMenuToggleBtn.append(event.target.textContent)
        
            itemsMenu.classList.remove( 'open' );
        
            isDesktop ? setPinToMenu(targetElem):'';
            
        }
    
    }  
  
}

// set Pin to Menu (on scroll)
function setPinToMenu(targetElem) {
    if(menuController) {
        menuController.destroy(true);
    }
    let menuController;

    menuController = new ScrollMagic.Controller(); 
    if (document.documentElement.clientWidth >= 992) {
        let menuController;

        menuController = new ScrollMagic.Controller(); 

        const staticBlock = targetElem.querySelector(".b-restaurant-menu__col--heading__wrapper");
        const sectionHeight = targetElem.offsetHeight;
        const staticBlockHeight = staticBlock.offsetHeight;
        const duration = sectionHeight - staticBlockHeight > 0 ? sectionHeight - staticBlockHeight : 1;

        const pinBlock = new ScrollMagic.Scene({
            triggerElement: staticBlock,
            offset: 0,
            duration: `${duration}px`,
            reverse:true
        }).triggerHook(0)
        .setPin(staticBlock)
        .addTo(menuController);
    };
}